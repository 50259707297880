import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import SEO from '../components/organisms/seo';
import NewsPreview from '../components/molecules/newsPreview';
import { media } from '../utils/media-queries';
import { convertTypes } from '../utils/helper';
import { ease } from '../utils/easing';
import { LayoutContext } from '../components/organisms/layoutProvider';
import NewsTransitionOverlay from '../components/sections/news/overlay';

const NewsPage = ({ data }) => {
  const { transition } = useContext(LayoutContext);
  const { title, articles } = data.news.nodes[0];

  const [activeTypes, setActiveTypes] = useState('');
  const [availableTypes, setAvailableTypes] = useState([]);
  const [hideInactive, setHideInactive] = useState(true);

  /** sort out all unsused Types */
  useEffect(() => {
    const allGivenTypes = articles
      .map((article) => article.type.type)
      .reduce((acc, val) => acc.concat(val), []);

    setAvailableTypes(
      allGivenTypes.filter((tag, i) => allGivenTypes.indexOf(tag) === i)
    );
  }, [articles, activeTypes]);

  return (
    <>
      <SEO siteTitle="News" />
      <NewsTransitionOverlay transition={transition} />
      <StyledNewsHeader>
        <div
          className="filter"
          onMouseEnter={() => setHideInactive(!hideInactive)}
          onMouseLeave={() => setHideInactive(!hideInactive)}
        >
          <span>Showing</span>
          <span className="line" />
          <div className="types">
            <motion.button
              initial={{ width: 'auto' }}
              animate={{
                width: !activeTypes || !hideInactive ? 'auto' : 0,
              }}
              style={{
                overflow: !activeTypes ? 'visible' : 'hidden',
              }}
              transition={{ duration: 0.3 }}
              onClick={() => setActiveTypes('')}
              whileHover={{ opacity: 0.1 }}
            >
              All
            </motion.button>
            {availableTypes.map((type, i) => (
              <motion.button
                initial={{ width: 'auto' }}
                animate={{
                  width:
                    !hideInactive || activeTypes.includes(type) ? 'auto' : 0,
                }}
                transition={{ duration: 0.2 }}
                style={{
                  overflow: activeTypes.includes(type) ? 'visible' : 'hidden',
                }}
                key={i}
                type="button"
                onClick={() => setActiveTypes(type)}
                whileHover={{ opacity: 0.1 }}
              >
                <motion.span
                  animate={{
                    marginLeft: !hideInactive ? '20px' : '0',
                  }}
                  transition={{ delay: 0.1, duration: 0.4 }}
                >
                  {convertTypes(type)}
                </motion.span>
              </motion.button>
            ))}
          </div>
        </div>
        <p className="hl">{title}</p>
      </StyledNewsHeader>
      <StyledNews
        initial={{ y: 200 }}
        animate={{ y: 0 }}
        transition={{ duration: 0.4, ease: ease.inOutSnap }}
      >
        <AnimatePresence exitBeforeEnter>
          {articles
            .slice(0)
            .map(
              (article, i) =>
                article.type.type.includes(activeTypes) && (
                  <NewsPreview
                    key={i}
                    newsPage
                    slug={article.slug}
                    type={article.type.type}
                    description={article.headline}
                    logo={article.logo}
                    date={article.date}
                    time={article.time}
                    src={article.mainImage?.asset.gatsbyImageData}
                    alt={article.alt}
                    className={i === 3 && !activeTypes ? 'landscape' : ''}
                  />
                )
            )}
        </AnimatePresence>
      </StyledNews>
    </>
  );
};

const StyledNewsHeader = styled.div`
  color: var(--white);
  padding-top: var(--sp-13);
  display: flex;
  flex-direction: row;
  padding-right: var(--sp-1-8);
  padding-left: var(--sidebar-width);
  padding-bottom: 4rem;
  justify-content: space-between;
  align-items:center;

  .hl {
    color: var(--white);
    text-align: right;
    
  }

  .types {
    max-width: 60%;
  }

  .filter {
    display: flex;
    cursor: pointer;
    display: flex;
    align-items: flex-end !important;
 
    

    .line {
      height: 1.5px;
      background: var(--white);
      width: 30px;
      transform: translateY(-10px);
      display: inline-block;
      margin: 0 var(--sp-1);
    }
  }

  @media ${media.M} {
    padding-left: var(--sidebar-width-at-m);

    .filter {
      padding-bottom: 0;
      // transform: translateY(var(--fs-subhl));
      align-items: flex-start !important;

      .line {
        transform: translateY(17.5px);
      }
    }

    .types {
      max-width: 100%;
    }

    .line {
      width: 48px;
    }
  }
`;

const StyledNews = styled(motion.div)`
  display: flex;
  flex-wrap: wrap;

  @media ${media.M} {
    margin-left: var(--sidebar-width-at-m);
    margin-right: calc(var(--sp-1-8) - var(--sp-3));
  }
`;

export const query = graphql`
  query News {
    news: allSanityNews {
      nodes {
        title
        articles {
          headline
          type {
            type
          }
          slug
          logo
          time
          date
          alt
          mainImage {
            asset {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;

NewsPage.propTypes = {
  data: PropTypes.object,
};

export default NewsPage;
